import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Globals } from '../globals';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  public urlTicket = '';
  public tipoPago = '';
  constructor(private http: HttpClient,private globals: Globals) { }

  pagar(data) {
    return this.http.post(`${this.globals.apiUrl}/checkout/pagar`, {
      data: data
    }).pipe(catchError(this.handleError));
  }

  verificarDescuento(codigo) {
    return this.http.get(`${this.globals.apiUrl}/descuentos/verificar?codigo=${codigo}`).pipe(catchError(this.handleError));
  }

  getEstadosMX(): Observable<any>{
    return this.http.get('assets/json/estadosMX.json');
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
        console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
